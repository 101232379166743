import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';
import { Office } from '@app/core/models/office.model';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '@base/environments/environment';
import { Constants } from '@base/src/app/core/constants/constants';
import { PictureSource } from '@app/core/models/picture-source';
import { UtilsService } from '@app/services/utils.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { PictureSourceComponent } from '../ui/picture-source/picture-source.component';

@Component({
  selector: 'qr-card-info-office',
  templateUrl: './qr-card-info-office.component.html',
  styleUrls: ['./qr-card-info-office.component.scss'],
  standalone: true,
  imports: [PictureSourceComponent, L10nTranslationModule, CommonModule],
})
export class QrCardInfoOfficeComponent implements OnInit {
  @Input() data!: Office;
  @Input() isImgTop = false;
  resizedImageWebp: string = '';

  imageBucketResizeUrl: string = environment.imageBucketResizeUrl;
  // Picture and Source
  officesResizedImages: PictureSource = {
    text: Constants.FOLDER_OFFICES,
    docType: 'image/' + Constants.DOCTYPE_WEBP,
    loading: PictureSource.LOADING_LAZY,
  };

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private titleService: Title,
    private metaTagService: Meta,
    private utils: UtilsService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit(): void {
    let country = '';

    switch (environment.node) {
      case Constants.NODE_ARGENTINA:
        country = 'Argentina';
        break;

      case Constants.NODE_URUGUAY:
        country = 'Uruguay';
        break;

      case Constants.NODE_ECUADOR:
        country = 'Ecuador';
        break;

      default:
        break;
    }
    if (this.data) this.resizePhotosWebp();
    if (this.isImgTop && this.data) {
      const title =
        this.data.name + ' - ' + this.data.address + ' - ' + country + '.';
      this.titleService.setTitle(title);
      this.metaTagService.updateTag({
        name: 'description',
        content:
          'Visitá ' +
          this.data.name +
          ' en ' +
          this.data.address +
          ', en ' +
          country +
          '.',
      });
      this.metaTagService.addTag({
        property: 'og:image',
        content: this.data.photo,
      });
      const url = isPlatformBrowser(this.platformId)
        ? window.location.href
        : null;
      this.metaTagService.addTag({
        property: 'og:url',
        content: url || '',
      });
      this.metaTagService.addTag({
        property: 'og:title',
        content: this.data.name,
      });
    }
  }

  resizePhotosWebp(): void {
    if (this.data.rawPhoto == null) {
      this.resizedImageWebp = '';
      return;
    }

    this.resizedImageWebp = this.utils.generateImageWithSize(
      this.data.rawPhoto,
      '180x180'
    );
  }
}
